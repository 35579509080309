const thumbnails = ["https://i.ibb.co/DCzVN2m/butts.jpg"
,"https://i.ibb.co/qNcyrb2/dw.jpg"
,"https://i.ibb.co/0BSgwN8/jb.jpg"
,"https://i.ibb.co/HV4cnYk/jh-1.jpg"
,"https://i.ibb.co/K28gsmn/jh.jpg"
,"https://i.ibb.co/XL1JH2j/jk-1.jpg"
,"https://i.ibb.co/qJz9zS3/jk.jpg"
,"https://i.ibb.co/x7VZN9c/lwp.jpg"
,"https://i.ibb.co/CnxPq7D/p-210.jpg"
,"https://i.ibb.co/Cw20GXV/p-240.jpg"
,"https://i.ibb.co/GMD6srx/p-320.jpg"
,"https://i.ibb.co/pPhc6PZ/p-400.jpg"
,"https://i.ibb.co/qpdd79F/s-210.jpg"
,"https://i.ibb.co/4pHKPFY/s-240.jpg"
,"https://i.ibb.co/V21n688/s-320.jpg"
,"https://i.ibb.co/m4NkX33/swp.jpg"
,"https://i.ibb.co/BtVckRW/w-210.jpg"
,"https://i.ibb.co/Sx0rHVh/w-400.jpg"
,"https://i.ibb.co/9GYj9DM/ww-240.jpg"
,"https://i.ibb.co/StpLC8Y/ww320.jpg"];


export default thumbnails;