import React from 'react';
import LandingPage from './components/landingPage/landingPage';

const App = () => {
    return(
        <div>
            <LandingPage/>
        </div>
    );
}

export default App